import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
// import "@/permission";
import './utils/'; 
import Vant from "vant";
import "vant/lib/index.css";
import "@/styles/index.css";

import VueAnimXYZ from '@animxyz/vue'
import '@animxyz/core' // Import css here if you haven't elsewhere

Vue.use(VueAnimXYZ)

import animate from "animate.css";
Vue.use(animate)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

import moment from 'moment'
import 'moment/locale/zh-cn'
// import VConsole from "vconsole";
Vue.prototype.$moment = moment;

Vue.use(Vant);

Vue.config.productionTip = false;

// new VConsole();

import translate from 'i18n-jsautotranslate'
translate.setUseVersion2() //设置使用v2.x 版本
translate.selectLanguageTag.show = false //是否显示切换栏
translate.listener.start() //监控页面动态渲染的文本进行自动翻译
Vue.prototype.$translate = translate

import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  ak: 'kfoQJcsHfEY4iq6C8ZaNQdybZTp6JC9Z'
});

// import vueMeta from 'vue-meta'
// Vue.use(vueMeta)

import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

Vue.prototype.$bus = new Vue();

new Vue({
  router,
  store,
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  },
  render: (h) => h(App),
}).$mount("#app");
