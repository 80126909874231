<template>
  <div id="app">
    
    <router-view v-if="isRouterAlive"></router-view> 
  </div>
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    // HelloWorld,
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload(){
      this.isRouterAlive= false;
      this.$nextTick(() => {
        this.isRouterAlive= true;
      })
    }
  },
  created() {
    this.$bus.$on('app-reload',this.reload);
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* min-height: 100vh; */
}
body {
    min-width: 100vw;
    min-height: 100vh;
    background: #f7fbfd;
}

</style>
