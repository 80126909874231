import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;

// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

export const constantRoutes = [
  {
    path: "/",
    redirect: "/login",
    meta: {
      metaInfo: {
        title: "纯子能源 title",
        keywords: "纯子能源 keywords",
        description: "纯子能源 description"
      }
    }
  },
  {
    path: "/login",
    name: "/login",
    component: () => import("@/views/login"),
    meta: {
      metaInfo: {
        title: "纯子能源 title",
        keywords: "纯子能源 keywords",
        description: "纯子能源 description"
      }
    }
  },
  {
    path: "/home",      // 首页选择页
    name: "Home",
    component: () => import("@/views/home"),
    meta: {
      metaInfo: {
        title: "首页 title",
        keywords: "首页 keywords",
        description: "首页 description"
      }
    }
  },
  {
    path: "/homewe",      // 关于我们
    name: "Homewe",
    component: () => import("@/views/homewe"),
    meta: {
      metaInfo: {
        title: "关于我们 title",
        keywords: "关于我们 keywords",
        description: "关于我们 description"
      }
    }
  },
  {
    path: "/homeChan",      // 产品中心
    name: "homeChan",
    component: () => import("@/views/homeChan"),
    meta: {
      metaInfo: {
        title: "产品中心 title",
        keywords: "产品中心 keywords",
        description: "产品中心 description"
      }
    }
  },
  {
    path: "/homeYing",      // 应用管理
    name: "homeYing",
    component: () => import("@/views/homeYing"),
    meta: {
      metaInfo: {
        title: "应用管理 title",
        keywords: "应用管理 keywords",
        description: "应用管理 description"
      }
    }
  },
  {
    path: "/homeWo",      // 联系我们
    name: "homeWo",
    component: () => import("@/views/homeWo"),
    meta: {
      metaInfo: {
        title: "联系我们 title",
        keywords: "联系我们 keywords",
        description: "联系我们 description"
      }
    }
  },

  {
    path: "/shouhome",      // 手机端首页
    name: "/shouhome",
    component: () => import("@/views/shouhome"),
    meta: {
      metaInfo: {
        title: "首页 title",
        keywords: "首页 keywords",
        description: "首页 description"
      }
    }
  },
  {
    path: "/shouAbout",      // 手机端关于我们
    name: "/shouAbout",
    component: () => import("@/views/shouAbout"),
    meta: {
      metaInfo: {
        title: "关于我们 title",
        keywords: "关于我们 keywords",
        description: "关于我们 description"
      }
    }
  },
  {
    path: "/shouChan",      // 手机端产品中心
    name: "/shouChan",
    component: () => import("@/views/shouChan"),
    meta: {
      metaInfo: {
        title: "产品中心 title",
        keywords: "产品中心 keywords",
        description: "产品中心 description"
      }
    }
  },
  {
    path: "/shouChanDetails",      // 手机端产品中心详情
    name: "/shouChanDetails",
    component: () => import("@/views/shouChanDetails"),
    meta: {
      metaInfo: {
        title: "产品中心详情 title",
        keywords: "产品中心详情 keywords",
        description: "产品中心详情 description"
      }
    }
  },
  {
    path: "/shouYing",      // 手机端应用中心
    name: "/shouYing",
    component: () => import("@/views/shouYing"),
    meta: {
      metaInfo: {
        title: "应用中心 title",
        keywords: "应用中心 keywords",
        description: "应用中心 description"
      }
    }
  },
  {
    path: "/shouYingDetails",      // 手机端应用中心详情
    name: "/shouYingDetails",
    component: () => import("@/views/shouYingDetails"),
    meta: {
      metaInfo: {
        title: "应用中心详情 title",
        keywords: "应用中心详情 keywords",
        description: "应用中心详情 description"
      }
    }
  },


  {
    path: "/shouContact",      // 手机端联系我们
    name: "/shouContact",
    component: () => import("@/views/shouContact"),
    meta: {
      metaInfo: {
        title: "联系我们 title",
        keywords: "联系我们 keywords",
        description: "联系我们 description"
      }
    }
  },

];

const createRouter = () =>
  new Router({
    mode: 'history', // require service support
    scrollBehavior: () => ({ x: 0,y: 0 }),

    routes: constantRoutes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
